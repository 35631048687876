import * as React from "react";

import { Layout } from "components";

import {
  PrivacyPolicyContent,
  PrivacyPolicyPageHeader,
} from "templates/privacy-policy";

const SecurityPolicyPage = () => (
  <Layout title="Privacy Policy" theme="light">
    <PrivacyPolicyPageHeader />
    <PrivacyPolicyContent />
  </Layout>
);

export default SecurityPolicyPage;
