import { PageHeader } from "components";
import React from "react";

const PrivacyPolicyPageHeader = () => {
  return (
    <PageHeader
      title={<>Parkway’s Privacy Policy</>}
      subheading={<>Version Date: 4/11/2021</>}
      center
      theme="light"
    />
  );
};

export { PrivacyPolicyPageHeader };
