import { Container, Text } from "components";
import React from "react";

const PrivacyPolicyContent = () => {
  return (
    <Container>
      <div className="policy-content">
        <Text value="Acknowledgment and Acceptance of Terms" variant="h4" />
        <Text variant="p18" as="div">
          <p>
            At Parkway ("Parkway” "we,” "us," or "our") we respect the privacy
            of our online visitors and registered users ("Users") as such we
            will take reasonable steps to protect your information.
          </p>

          <p>
            This Privacy Policy describes how we collect and use Personal Data
            from customers and other individuals collectively ("YOU",
            "YOUR"),who access or use our websites, which are collectively
            referred to herein as our ("Website"), products, or services (the
            "Services").
          </p>

          <p>
            "Personal Data" is any information that relates to an identified or
            identifiable individual. By using our Services, you understand that
            we will collect and use your data as described in this Privacy
            Policy. Parkway developed this Privacy Policy to explain how we may
            manage, process, share, store, and transfer Personal Data that you
            provide when you use any of our products. All collection, use, and
            disclosure of your business and Personal Data will be governed under
            this Privacy Policy.
          </p>

          <p>
            The Websites and the Services are not meant for individuals under
            the age of 18 years of age. We do not knowingly collect personal
            information from individuals under 18. If you are under 18, we
            request that you not provide Personal Data through this Website or
            our Services. If we learn we have collected or received personal
            information from an individual under 18, we will delete that
            information. If you believe we might have any information from or
            about a minor under 18, don't hesitate to contact us at
            dpo@parkwayprojects.com.
          </p>

          <p>
            By accessing our website and services, you acknowledge and fully
            understand this Privacy Policy Statement and freely consent to the
            information collection and use practices described in this Privacy
            Policy Statement.
          </p>
        </Text>

        <Text value="Information We Collect" variant="h4" />
        <Text variant="p18" as="div">
          <p>
            Parkway obtains Personal Data about you from various sources to
            provide and manage our products and services. You may be a visitor
            to one of our websites, a user of one or more of our Services, or a
            customer to a user of our Services. In cases where we act as a data
            processor, your Personal Data may also be governed by a separate
            agreement between you and a third party that is interacting with our
            Services on your behalf, although generally subject to the
            provisions of relevant laws on data protection, including the NDPR.
            If you have questions about this scenario, then you should direct
            those questions to the user with whom you are transacting. Where
            such questions come to us directly, we may respond to same through
            the appropriate party who directly obtained your Personal Data.
          </p>
          <p>Personal information we collect directly from you may include;</p>
          <ul>
            <li>
              Financial information: such as Bank Verification Number (BVN) and
              personal account details.
            </li>
            <li>
              Identity Data: Information such as your full name(s), your sex,
              nationality, photograph, State, LGA and your date of birth,
              National Identity number
            </li>
            <li>
              Personal contact information including your home address, personal
              telephone number(s) and personal email address.
            </li>
            <li>
              Business contact information if applicable including business
              email address, telephone number, and office address.
            </li>
            <li>
              Cardholder data: Information such as Primary Account Number (PAN),
              cardholder name or expiration date.
            </li>
          </ul>
        </Text>

        <Text value="How We obtain Personal information:" variant="h4" />

        <Text variant="p18" as="div">
          <p>We collect information directly when you:</p>
          <ul>
            <li>Create an account for any of our products or services.</li>
            <li>Fill our online forms</li>
            <li>Contact our product support team</li>
            <li>Contact us through any of our websites</li>
          </ul>
        </Text>

        <Text value="Information learnt from your use" variant="h4" />

        <Text variant="p18" as="div">
          This information is collected for analysis and evaluation to help us
          improve our site and the services and products we provide. This data
          will not be used in association with any other personal information.
          <p>
            {" "}
            When you visit our website or subscribe to any of our services, our
            company servers will automatically record information that your
            browser sends whenever you visit a website. This data may include:
          </p>
          <ul>
            <li>Your computer's IP address</li>
            <li>Browser type</li>
            <li>The pages within the website you visit</li>
            <li>
              The time spent on those pages, items and information searched for
              on our site, access times and dates, and other statistics.
            </li>
          </ul>
        </Text>

        <Text value="Use of Cookies" variant="h4" />
        <Text variant="p18">
          <>
            A cookie is a small text file placed on your hard disk by a Web page
            server. When applicable, we use cookies to personalize or enhance
            your user experience. Cookies are uniquely assigned to you and can
            only be read by a Web Server in the domain that issued the cookie to
            you. One of the primary purposes of cookies is to provide a
            convenience feature to save you time. For example, if you
            personalize a web page or navigate a site, a cookie helps the site
            recall your specific information on subsequent visits. Hence, this
            simplifies the process of delivering relevant content and eases site
            navigation by providing and saving your preferences and login
            information as well as providing personalized functionality. If you
            reject cookies by changing your browser settings, then be aware that
            this may disable some of the functionality on our website.
          </>
        </Text>

        <Text value="Purpose of processing" variant="h4" />

        <Text variant="p18" as="div">
          <p>
            {" "}
            We will only use and share your information where it is necessary
            for us to carry out our lawful business activities. The lawful basis
            for processing your Personal Information are:
          </p>

          <p>
            <b>Legal obligation</b> <br />
            When you sign up for one of our products or services (and throughout
            your relationship with us), we are required by law to collect and
            process certain personal information about you. This ensures we are
            fully compliant with all applicable financial legislation such as
            AntiMoney Laundering and Counter-Terrorist Financing Laws.
          </p>

          <p>
            <b>Contractual Necessity</b>
            <br />
            We may process your information where it is necessary to enter a
            contract with you to provide our products or services or perform our
            obligations under that contract.
          </p>

          <p>
            <b>Legitimate interests</b>
            <br />
            We may process your information in our legitimate interests as an
            organization and without prejudicing your interests or fundamental
            rights and freedoms.
          </p>

          <p>
            <b>Consent</b>
            <br />
            You agree to us collecting your Personal Information by using our
            Services.
          </p>
        </Text>

        <Text value="Use and Disclosure of Personal Information" variant="h4" />
        <Text variant="p18" as="div">
          <p>
            When using our Services: We may collect and use the following kinds
            of personal information provided by you for the purposes of
            registering with us, information about transactions carried out over
            the website, information that you provide for the purpose of
            subscribing to our services and any other information that you send
            to us.
          </p>

          <p>
            Marketing and Promotions: We may use your personal information to
            send you information about our services and products, supply you
            services that you purchase, send you statements and invoices,
            collect payments from you, and send you marketing communications.
          </p>

          <p>
            Third Party disclosure: Where we disclose your personal information
            to our agents or subcontractors for these purposes, the agent or
            sub-contractor in question will be obligated to use that personal
            information per the terms of this privacy statement.
          </p>

          <p>
            In addition to the disclosures necessary for the purposes identified
            elsewhere above, we may disclose your personal information to the
            extent that it is required to do so by law, in connection with any
            legal proceedings or prospective legal proceedings, and to
            establish, exercise or defend our legal rights. Also, personal
            information may be disclosed where it is necessary to prevent a
            threat to life or health. We may also disclose information about our
            users to law enforcement officers or others in good faith that such
            disclosure is reasonably necessary to: enforce our Terms of Use,
            respond to claims that any posting or other content violates the
            rights of third-parties; or protect the rights, property, or
            personal safety of Parkway, its users or the general public.
          </p>

          <p>
            We may provide the data collected from you to members of our group
            companies or other third parties, including our agents and
            contractors, in connection with the service. We will inform you
            before disclosing your information to any third party. Suppose you
            can be identified from the information that is disclosed. In that
            case, we will not disclose such information without prior
            notification to you and we will obtain your permission to do so. You
            may inform us at any time not to pass on or share your personal
            information with any third parties.
            <br />
            The information you provide when you subscribe to any of our
            services may be required to access our products. We will store this
            information and data and use it to contact you, provide you with
            further details of the services and otherwise for the normal use,
            provision of services and improvement of the site, unless you ask us
            not to do so.
            <br />
            The information may be disclosed to third parties to enable you to
            gain access to any restricted part of the site, enable you to
            receive data that you have requested to be sent to you by post or
            for you to receive specified services.
          </p>
        </Text>

        <Text value="Data Protection Rights" variant="h4" />
        <Text variant="p18" as="div">
          <p>
            We want to make sure you are fully aware of all your data protection
            rights. Every user is entitled to the following:
          </p>

          <ul>
            <li>
              The right to access – You have the right to request copies of your
              data. We may charge you a small fee for this service.
            </li>
            <li>
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you think is
              incomplete.
            </li>
            <li>
              The right to erasure – You have the right to request that we erase
              your data.
            </li>
            <li>
              The right to restrict processing – You have the right to request
              that we restrict the processing of your data.
            </li>
            <li>
              The right to object to processing – You have the right to object
              to our processing of your personal data.
            </li>
            <li>
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization or directly to you under certain conditions.
            </li>
          </ul>

          <p>
            If you would like to exercise any of these rights, don't hesitate to
            get in touch with us at <b>dpo@parkwayprojects.com.</b>
          </p>
        </Text>

        <Text value="Data Security" variant="h4" />
        <Text variant="p18" as="div">
          <p>
            We are committed to managing your information with the highest
            standards of information security. We have implemented various
            measures, following best practice standards such as the Information
            security management standard (ISO 27001) and Payment Card Industry
            Data Security Standard ("PCI-DSS"), to help ensure the security and
            confidentiality of personal information housed in our system.
          </p>

          <p>
            These measures include appropriate administrative, technical and
            physical safeguards designed to protect against anticipated threats
            or hazards to the security or integrity of such information and
            unauthorized access to or use of such information. We use computer
            safeguards such as firewalls and data encryption, enforce physical
            access to our buildings and files and only authorize access to
            personal information to employees who require it to fulfil their job
            responsibilities.
          </p>

          <p>
            We maintain a business continuity plan as a contingency plan, which
            identifies our business functions, and assets (including personal
            data) that would need to be maintained in the event of a disaster
            and set out the procedures for protecting and restoring them if
            necessary. In respect of detection and investigation of breaches
            where they occur, we have in place relevant controls, which should
            alert us to a breach in security. We endeavor to investigate every
            breach of security.
          </p>
        </Text>

        <Text value="Changes to the Privacy Policy" variant="h4" />
        <Text variant="p18">
          Parkway reserves the right to modify and change the Privacy Policy at
          any time. Any changes to this policy will be published on our site. A
          notice will not apply where a change relates to adding a new service,
          extra functionality to the existing services, or any other change that
          we believe in our reasonable opinion to neither reduce your rights nor
          increase your responsibilities. In such instances, the change will be
          made without prior notice to you and shall be effective immediately
          upon giving notice of it.
        </Text>

        <Text
          variant="h4"
          value="Enquiries and Complaints about Privacy Policy Statement"
        />
        <Text variant="p18">
          If you are not satisfied with how, we handle your personal data,
          please don't hesitate to contact us at <b>dpo@parkwayprojects.com</b>
        </Text>
      </div>
    </Container>
  );
};

export { PrivacyPolicyContent };
